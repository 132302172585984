const projectID = process.env.REACT_APP_INFURA_ID;

let networkName = '';
let chainId = '';
let networkURL = '';
let poolContracts = [];
let fixedPoolContracts = [];
let etherscan = '';
let deploymentBlockNumber = '';
let supportedNetworks = {};

const ENV = process.env.REACT_APP_ETH_PROVIDER;
switch (ENV) {
  case 'mainnet':
    supportedNetworks = {
      ethereum: {
        networkName: 'Ethereum Mainnet',
        chainId: 1,
        networkURL: `https://mainnet.infura.io/v3/${projectID}`,
        swapTokenContract: '0xCC4304A31d09258b0029eA7FE63d032f52e44EFe',
        stakingContractAddress: '0x5A753021CE28CBC5A7c51f732ba83873D673d8cC',
        explorer: 'https://etherscan.io/tx/',
        contractLink: 'https://etherscan.io/address/0x5A753021CE28CBC5A7c51f732ba83873D673d8cC'
      },
      matic: {
        networkName: 'Polygon Mainnet',
        chainId: 137,
        networkURL: 'https://rpc-mainnet.matic.network',
        explorer: 'https://polygonscan.com/tx/',
        swapTokenContract: '0x3809dcDd5dDe24B37AbE64A5a339784c3323c44F',
        stakingContractAddress: '0xb16a2a990a4EAdB677505fdF5bdb299f703fce25',
        contractLink: 'https://polygonscan.com/address/0xb16a2a990a4EAdB677505fdF5bdb299f703fce25'
      },
      bsc: {
        networkName: 'Binance Mainnet',
        chainId: 56,
        networkURL: 'https://bsc-dataseed1.binance.org:443',
        explorer: 'https://bscscan.com/tx/',
        swapTokenContract: '0x82443a77684a7da92fdcb639c8d2bd068a596245',
        stakingContractAddress: '0x1714FBCFb62A4974C83EaFA0fAEC12191da6c71e',
        contractLink: 'https://bscscan.com/address/0x1714FBCFb62A4974C83EaFA0fAEC12191da6c71e'
      },
      avalanche: {
        networkName: 'Avalanche Mainnet',
        chainId: 43114,
        networkURL: 'https://api.avax.network/ext/bc/C/rpc',
        explorer: 'https://snowtrace.io/tx/',
        swapTokenContract: '0xc7b5d72c836e718cda8888eaf03707faef675079',
        stakingContractAddress: '0x405eF38d44ACfF35b293410fEF9d8de1369Bece4',
        contractLink: 'https://snowtrace.io/address/0x405eF38d44ACfF35b293410fEF9d8de1369Bece4'
      },
      unstakingPeriod: 60 * 60 * 24 * 7
    };
    break;
  default:
    supportedNetworks = {
      ethereum: {
        networkName: 'ropsten',
        chainId: 3,
        networkURL: `https://ropsten.infura.io/v3/${projectID}`,
        swapTokenContract: '0x9acf325c258dbad2a2722f26447d68ae944db3bc',
        stakingContractAddress: '0xe0DaB85ea7752a646fe03a0B4208C58bbbAcA81B',
        explorer: 'https://ropsten.etherscan.io/tx/',
        contractLink: ''
      },
      matic: {
        networkName: 'Mumbai Matic',
        chainId: 80001,
        networkURL: 'https://rpc-mumbai.matic.today',
        swapTokenContract: '0xDf68aD003175883C97c10F37681613Dc6A9B278A',
        stakingContractAddress: '0xe416295D311828eE0A3Cc1b8A344062e28960b34',
        explorer: 'https://mumbai.polygonscan.com/tx/',
        contractLink: ''
      },
      bsc: {
        networkName: 'Binance Testnet',
        chainId: 97,
        networkURL: 'https://data-seed-prebsc-1-s1.binance.org:8545',
        explorer: 'https://testnet.bscscan.com/tx/',
        swapTokenContract: '0x7A549fA5A7Ce81988a768B1A5FDa8c2Ec995890B',
        stakingContractAddress: '0x44dA889fc9389D3e1A239Fd7AD2183B7E88FDe44',
        contractLink: ''
      },
      avalanche: {
        networkName: 'Avalanche Testnet',
        chainId: 43113,
        networkURL: 'https://api.avax-test.network/ext/bc/C/rpc',
        explorer: 'https://testnet.snowtrace.io/tx/',
        swapTokenContract: '0xa50bd5EF3B95Bfe6429cB6815801D535D55d0D25',
        stakingContractAddress: '0x1E7BF166bb58909E7cD636593e71D33955049C8a',
        contractLink: ''
      },
      unstakingPeriod: 60 * 60 * 24 * 1
    };
}

const getFixedPoolContractsByChainId = (chainId) => {
  let fixedPoolContractsByChainId = {};
  switch (chainId) {
    case 56:
      fixedPoolContractsByChainId = {
        iPhone: {
          symbol: 'SWAP',
          poolContractAddress: '0xDbFcb9ce50eD53617eD50aFc0422bA28a50842EF',
          stakeToken: {
            address: '0x82443A77684A7Da92FdCB639c8d2Bd068a596245',
            decimals: 18
          },
          rewardToken: {
            address: '0x4650a2e425f4eddb94694294bda41f609d6fddff',
            decimals: 18
          }
        }
      };
      break;
    case 137:
      fixedPoolContractsByChainId = {
        iPhone: {
          symbol: 'SWAP',
          poolContractAddress: '0xDa719fE5443a2EFD5a61Ceb11fcb2a02FCeb8923',
          stakeToken: {
            address: '0x3809dcDd5dDe24B37AbE64A5a339784c3323c44F',
            decimals: 18
          },
          rewardToken: {
            address: '0xca7f543b0e3386b508b21cd11ddc34008f5e7d02',
            decimals: 18
          }
        }
      };
      break;
    case 80001:
      fixedPoolContractsByChainId = {};
      break;
    case 3:
      fixedPoolContractsByChainId = {
        wMBX: {
          symbol: 'wMBX',
          poolContractAddress: '0x043d4f8e4adcb4b58D6B17cA57b92e2204026a36',
          stakeToken: {
            address: '0x9acF325c258DbaD2A2722F26447d68aE944db3BC',
            decimals: 18
          },
          rewardToken: {
            address: '0xd893165b41c548bdc71b94634b542aaad247f2f8',
            decimals: 18
          }
        },
        MOD: {
          symbol: 'MT',
          poolContractAddress: '0xe71cb0142c7557Ed001E1829f51D2805c8896337',
          stakeToken: {
            address: '0x9acF325c258DbaD2A2722F26447d68aE944db3BC',
            decimals: 18
          },
          rewardToken: {
            address: '0xd893165b41c548bdc71b94634b542aaad247f2f8',
            decimals: 18
          }
        }
      };
      break;
    case 1:
      fixedPoolContractsByChainId = {
        wMBX: {
          symbol: 'wMBX',
          poolContractAddress: '0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a',
          stakeToken: {
            address: '0xCC4304A31d09258b0029eA7FE63d032f52e44EFe',
            decimals: 18
          },
          rewardToken: {
            address: '0x71ba91dC68C6a206Db0A6A92B4b1De3f9271432d',
            decimals: 18
          }
        },
        MODStaking: {
          symbol: 'MOD',
          poolContractAddress: '0x3093896c81c8d8b9BF658Fbf1AeDe09207850cA2',
          stakeToken: {
            address: '0xea1ea0972fa092dd463f2968f9bb51cc4c981d71',
            decimals: 18
          },
          rewardToken: {
            address: '0xea1ea0972fa092dd463f2968f9bb51cc4c981d71',
            decimals: 18
          }
        },
        SKRT: {
          symbol: 'SKRT',
          poolContractAddress: '0x0b0C54d20183d320D6d3eA4Eb4Eb10711c4ec431',
          stakeToken: {
            address: '0xcc4304a31d09258b0029ea7fe63d032f52e44efe',
            decimals: 18
          },
          rewardToken: {
            address: '0x887168120cb89Fb06F3E74Dc4AF20D67dF0977f6',
            decimals: 18
          }
        },
        SKRTStaking: {
          symbol: 'SKRT',
          poolContractAddress: '0x8Ad97ed3674cDA319761AfC8785c831002E2bff9',
          stakeToken: {
            address: '0x887168120cb89Fb06F3E74Dc4AF20D67dF0977f6',
            decimals: 18
          },
          rewardToken: {
            address: '0x887168120cb89Fb06F3E74Dc4AF20D67dF0977f6',
            decimals: 18
          }
        },
        MOD: {
          symbol: 'MOD',
          poolContractAddress: '0x34411849237508E0391884656D9c78b4E9fbAd50',
          stakeToken: {
            address: '0xCC4304A31d09258b0029eA7FE63d032f52e44EFe',
            decimals: 18
          },
          rewardToken: {
            address: '0xea1ea0972fa092dd463f2968f9bb51cc4c981d71',
            decimals: 18
          }
        },
        SKRT2: {
          symbol: 'SKRT',
          poolContractAddress: '0x0850a2fc0B7D95403d8b64510DBd79ebddD44bF4',
          stakeToken: {
            address: '0xcc4304a31d09258b0029ea7fe63d032f52e44efe',
            decimals: 18
          },
          rewardToken: {
            address: '0x887168120cb89Fb06F3E74Dc4AF20D67dF0977f6',
            decimals: 18
          }
        },
        CREAM: {
          symbol: 'CREAM',
          poolContractAddress: '0x5B6A360F0d4Fffb2997168cA1CE8134ca2F0766E',
          stakeToken: {
            address: '0x98e329eb5aae2125af273102f3440de19094b77c',
            decimals: 8
          },
          rewardToken: {
            address: '0xf94b5c5651c888d928439ab6514b93944eee6f48',
            decimals: 18
          }
        },
        TOKTOK: {
          symbol: 'TOKTOK',
          poolContractAddress: '0xbb0d6fAc9F449Ec94BCc42AF7d8bD752F7C66898',
          stakeToken: {
            address: '0x4fb721ef3bf99e0f2c193847afa296b9257d3c30',
            decimals: 8
          },
          rewardToken: {
            address: '0x4fb721ef3bf99e0f2c193847afa296b9257d3c30',
            decimals: 8
          }
        },
        TOKLP: {
          symbol: 'TOKLP',
          poolContractAddress: '0x12d8391014e7ad6Ff74FD38dfAbCCAE0466040F1',
          stakeToken: {
            address: '0xcce561cd3217dc5a6f9b9919fd3928cf3d0e282f',
            decimals: 18
          },
          rewardToken: {
            address: '0x4fb721ef3bf99e0f2c193847afa296b9257d3c30',
            decimals: 8
          }
        },
        iPhone: {
          symbol: 'SWAP',
          poolContractAddress: '0x8c89dB17DcdFFf9a76a0724d9dDf0490f1C4C80B',
          stakeToken: {
            address: '0xCC4304A31d09258b0029eA7FE63d032f52e44EFe',
            decimals: 18
          },
          rewardToken: {
            address: '0x76D2C2e92d4ccE6Fee0CAB69628A1A2DE190C20b',
            decimals: 18
          }
        },
        LED: {
          symbol: 'LED',
          poolContractAddress: '0x865A2333985B872AC4E689f87825703f9E936427',
          stakeToken: {
            address: '0xcc4304a31d09258b0029ea7fe63d032f52e44efe',
            decimals: 18
          },
          rewardToken: {
            address: '0x72de803b67b6ab05b61efab2efdcd414d16ebf6d',
            decimals: 18
          }
        },
        TOK: {
          symbol: 'TOK',
          poolContractAddress: '0x1ED30003165C24E1116553f514AbF0bF4D74274B',
          stakeToken: {
            address: '0xcc4304a31d09258b0029ea7fe63d032f52e44efe',
            decimals: 18
          },
          rewardToken: {
            address: '0x4Fb721eF3Bf99e0f2c193847afA296b9257d3C30',
            decimals: 8
          }
        },
        WBUY: {
          symbol: 'WBUY',
          poolContractAddress: '0x9AF09224AfBa0EcF1431d08DDbD0924f2047097C',
          stakeToken: {
            address: '0xcc4304a31d09258b0029ea7fe63d032f52e44efe',
            decimals: 18
          },
          rewardToken: {
            address: '0x396eC402B42066864C406d1ac3bc86B575003ed8',
            decimals: 2
          }
        }
      };
      break;
    case 43114:
      fixedPoolContractsByChainId = {
        DWETHVSO2: {
          symbol: 'DWETHVSO2',
          poolContractAddress: '0x4fA24241F238B4bF80855D0fbfDba139e890dD15',
          stakeToken: {
            address: '0x191823e48ff4ff831694644f02C570f4EDa4cC75',
            decimals: 18
          },
          rewardToken: {
            address: '0x846d50248baf8b7ceaa9d9b53bfd12d7d7fbb25a',
            decimals: 18
          }
        },
        DCTDDUSDT2: {
          symbol: 'DCTDDUSDT2',
          poolContractAddress: '0x6eEf17e2D3e23bFA00E14955C8B0747c2a9FFfCF',
          stakeToken: {
            address: '0x05ff108F9A204D9abE22d0b435e6503E3222d534',
            decimals: 18
          },
          rewardToken: {
            address: '0x8Db2dBdFB50480FE79F6576deAA4f6E68DcBfb15',
            decimals: 18
          }
        },
        AVAXDWETH2: {
          symbol: 'AVAXDWETH2',
          poolContractAddress: '0x0850a2fc0B7D95403d8b64510DBd79ebddD44bF4',
          stakeToken: {
            address: '0x50680439B7A7e6232F47e1c3FF7fd0198732Fc0E',
            decimals: 18
          },
          rewardToken: {
            address: '0x8Db2dBdFB50480FE79F6576deAA4f6E68DcBfb15',
            decimals: 18
          }
        },
        DWETHTOK2: {
          symbol: 'DWETHTOK2',
          poolContractAddress: '0xbE226b3d28E9d96ed34a3C9095D546636AC03578',
          stakeToken: {
            address: '0xC18b2eB5E17018Eb50DaEda804B4457eE7D66C50',
            decimals: 18
          },
          rewardToken: {
            address: '0xae9d2385Ff2E2951Dd4fA061e74c4d3deDD24347',
            decimals: 8
          }
        },
        DCTDTOK2: {
          symbol: 'DCTDTOK2',
          poolContractAddress: '0x89AB8F9a93d5020Be91298EF1F619f05cFe696A7',
          stakeToken: {
            address: '0xE9e4889c7BAC5a6369fd873e86E552C86eE619E6',
            decimals: 18
          },
          rewardToken: {
            address: '0x8Db2dBdFB50480FE79F6576deAA4f6E68DcBfb15',
            decimals: 18
          }
        },
        DCTDVSO2: {
          symbol: 'DCTDVSO2',
          poolContractAddress: '0x740b71cAFaBC9144C570B7bB4c444a0d46b2C5E9',
          stakeToken: {
            address: '0x497518f1d9Bdd7774C76C76caebc60C3247b95Ac',
            decimals: 18
          },
          rewardToken: {
            address: '0x8Db2dBdFB50480FE79F6576deAA4f6E68DcBfb15',
            decimals: 18
          }
        },
        DWETHVSO: {
          symbol: 'DWETHVSO',
          poolContractAddress: '0x41b842791efFa2dc7a80F0338a539b3dcFf6e2a9',
          stakeToken: {
            address: '0x191823e48ff4ff831694644f02C570f4EDa4cC75',
            decimals: 18
          },
          rewardToken: {
            address: '0x846d50248baf8b7ceaa9d9b53bfd12d7d7fbb25a',
            decimals: 18
          }
        },
        DCTDDUSDT: {
          symbol: 'DCTDDUSDT',
          poolContractAddress: '0xB04928bB796Ae5E4D3f95dc294d4c54D895e0437',
          stakeToken: {
            address: '0x05ff108F9A204D9abE22d0b435e6503E3222d534',
            decimals: 18
          },
          rewardToken: {
            address: '0x8Db2dBdFB50480FE79F6576deAA4f6E68DcBfb15',
            decimals: 18
          }
        },
        AVAXDWETH: {
          symbol: 'AVAXDWETH',
          poolContractAddress: '0x89dAe8328CF2d745F0D1c8972D6B97F75f5372f8',
          stakeToken: {
            address: '0x50680439B7A7e6232F47e1c3FF7fd0198732Fc0E',
            decimals: 18
          },
          rewardToken: {
            address: '0x8Db2dBdFB50480FE79F6576deAA4f6E68DcBfb15',
            decimals: 18
          }
        },
        DWETHTOK: {
          symbol: 'DWETHTOK',
          poolContractAddress: '0xf2da79Fcc2E6A945eC5e67b5F358C162c1EE34a6',
          stakeToken: {
            address: '0xC18b2eB5E17018Eb50DaEda804B4457eE7D66C50',
            decimals: 18
          },
          rewardToken: {
            address: '0xae9d2385Ff2E2951Dd4fA061e74c4d3deDD24347',
            decimals: 8
          }
        },
        DCTDTOK: {
          symbol: 'DCTDTOK',
          poolContractAddress: '0xFB7FFeAA69bcC97220E3D3F71Fb902de3Eb94807',
          stakeToken: {
            address: '0xE9e4889c7BAC5a6369fd873e86E552C86eE619E6',
            decimals: 18
          },
          rewardToken: {
            address: '0x8Db2dBdFB50480FE79F6576deAA4f6E68DcBfb15',
            decimals: 18
          }
        },
        DCTDVSO: {
          symbol: 'DCTDVSO',
          poolContractAddress: '0x782918773898c5B43b653a8eaC00E95c1cEB93A5',
          stakeToken: {
            address: '0x497518f1d9Bdd7774C76C76caebc60C3247b95Ac',
            decimals: 18
          },
          rewardToken: {
            address: '0x8Db2dBdFB50480FE79F6576deAA4f6E68DcBfb15',
            decimals: 18
          }
        },
        VERSO: {
          symbol: 'VERSO',
          poolContractAddress: '0xEf4a23B63070557669c7c54fDA7e675701DaD572',
          stakeToken: {
            address: '0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a',
            decimals: 18
          },
          rewardToken: {
            address: '0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a',
            decimals: 18
          }
        },
        VERSO2: {
          symbol: 'VERSO2',
          poolContractAddress: '0xDa719fE5443a2EFD5a61Ceb11fcb2a02FCeb8923',
          stakeToken: {
            address: '0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a',
            decimals: 18
          },
          rewardToken: {
            address: '0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a',
            decimals: 18
          }
        },
        VERSO3: {
          symbol: 'VERSO3',
          poolContractAddress: '0x454d379Ba89EB7BdA6AAA0420B056bD03fcF012B',
          stakeToken: {
            address: '0xc7b5d72c836e718cda8888eaf03707faef675079',
            decimals: 18
          },
          rewardToken: {
            address: '0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a',
            decimals: 18
          }
        },
        VERSO4: {
          symbol: 'VERSO4',
          poolContractAddress: '0xDa420bD5F676da1c8CB620313b0ba6D93e963E5F',
          stakeToken: {
            address: '0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a',
            decimals: 18
          },
          rewardToken: {
            address: '0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a',
            decimals: 18
          }
        },
        VERSO5: {
          symbol: 'VERSO5',
          poolContractAddress: '0x1adcceBB6E9dB3e1Fdc2920C037C9FD84e2ba6d1',
          stakeToken: {
            address: '0xc7b5d72c836e718cda8888eaf03707faef675079',
            decimals: 18
          },
          rewardToken: {
            address: '0x846d50248baf8b7ceaa9d9b53bfd12d7d7fbb25a',
            decimals: 18
          }
        },
        iPhone: {
          symbol: 'SWAP',
          poolContractAddress: '0x1Eac591CcCcC93AC522f5f16bca71cf5b511327d',
          stakeToken: {
            address: '0xc7b5d72c836e718cda8888eaf03707faef675079',
            decimals: 18
          },
          rewardToken: {
            address: '0x322978b3f396f584f089195be786c3da3b719429',
            decimals: 18
          }
        }
      };
      break;

    default:
      fixedPoolContractsByChainId = {};
  }

  return fixedPoolContractsByChainId;
};

const getPoolContractsByChainId = (chainId) => {
  let poolContractsByChainId = {};
  switch (chainId) {
    case 56:
      poolContractsByChainId = {};
      break;
    case 97:
      poolContractsByChainId = {};
      break;
    case 137:
      poolContractsByChainId = {};
      break;
    case 80001:
      poolContractsByChainId = {};
      break;
    case 3:
      poolContractsByChainId = {
        BCP: {
          symbol: 'BCP',
          poolContractAddress: '0x89BbDBeb3651ADfc17fBFad5636cfd57f34135e9',
          stakeToken: {
            address: '0x9acF325c258DbaD2A2722F26447d68aE944db3BC',
            decimals: 18
          },
          rewardToken: {
            address: '0x3Aef737699552e0e5A583B01E4f084aE0aF8eFd4',
            decimals: 16
          }
        },
        SOTA: {
          symbol: 'SOTA',
          poolContractAddress: '0x0f228F7FC8bB532898141109589AaF1BA597b0da',
          stakeToken: {
            address: '0x9acF325c258DbaD2A2722F26447d68aE944db3BC',
            decimals: 18
          },
          rewardToken: {
            address: '0x3Aef737699552e0e5A583B01E4f084aE0aF8eFd4',
            decimals: 16
          }
        },
        BCVT: {
          symbol: 'BCVT',
          poolContractAddress: '0x0f228F7FC8bB532898141109589AaF1BA597b0da',
          stakeToken: {
            address: '0x9acF325c258DbaD2A2722F26447d68aE944db3BC',
            decimals: 18
          },
          rewardToken: {
            address: '0x3Aef737699552e0e5A583B01E4f084aE0aF8eFd4',
            decimals: 18
          }
        }
      };
      break;
    case 1:
      poolContractsByChainId = {
        BCP: {
          symbol: 'BCP',
          poolContractAddress: '0x3850a7BfCa83Bd565326990cc8c096DEc504140d',
          stakeToken: {
            address: '0xCC4304A31d09258b0029eA7FE63d032f52e44EFe',
            decimals: 18
          },
          rewardToken: {
            address: '0xe047705117eb07e712c3d684f5b18e74577e83ac',
            decimals: 8
          }
        },
        SOTA: {
          symbol: 'SOTA',
          poolContractAddress: '0xd59eF6Cd1e78885AD88E333A4b14a44b57a3160C',
          stakeToken: {
            address: '0xCC4304A31d09258b0029eA7FE63d032f52e44EFe',
            decimals: 18
          },
          rewardToken: {
            address: '0x0dde6f6e345bfd23f3f419f0dfe04e93143b44fb',
            decimals: 18
          }
        },
        BCVT: {
          symbol: 'BCVT',
          poolContractAddress: '0xDa719fE5443a2EFD5a61Ceb11fcb2a02FCeb8923',
          stakeToken: {
            address: '0xCC4304A31d09258b0029eA7FE63d032f52e44EFe',
            decimals: 18
          },
          rewardToken: {
            address: '0x8D717AB5eaC1016b64C2A7fD04720Fd2D27D1B86',
            decimals: 18
          }
        }
      };
      break;
    case 43114:
      poolContractsByChainId = {};
      break;
    case 43113:
      poolContractsByChainId = {};
      break;

    default:
      poolContractsByChainId = {};
  }

  return poolContractsByChainId;
};

switch (ENV) {
  case 'testnet':
    networkName = 'ropsten';
    chainId = 3;
    networkURL = `https://ropsten.infura.io/v3/${projectID}`;
    poolContracts = getPoolContractsByChainId(chainId);
    fixedPoolContracts = getFixedPoolContractsByChainId(chainId);
    etherscan = 'https://ropsten.etherscan.io/tx/';
    deploymentBlockNumber = 8675331;
    break;
  case 'mainnet':
    networkName = 'mainnet';
    chainId = 1;
    networkURL = `https://mainnet.infura.io/v3/${projectID}`;
    poolContracts = getPoolContractsByChainId(chainId);
    fixedPoolContracts = getFixedPoolContractsByChainId(chainId);
    etherscan = 'https://etherscan.io/tx/';
    deploymentBlockNumber = 10847676;
    break;

  default:
    etherscan = 'https://etherscan.io/tx/';
}

export const network = {
  name: networkName,
  chainID: chainId,
  url: networkURL
};

export const setChainId = (chainId_) => {
  if (chainId_ !== chainId) {
    switch (chainId_) {
      case 56:
        networkName = 'bscMainnet';
        chainId = 56;
        networkURL = 'https://bsc-dataseed.binance.org/';
        poolContracts = getPoolContractsByChainId(chainId_);
        fixedPoolContracts = getFixedPoolContractsByChainId(chainId_);
        break;
      case 97:
        networkName = 'bscTestnet';
        chainId = 97;
        networkURL = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
        poolContracts = getPoolContractsByChainId(chainId_);
        fixedPoolContracts = getFixedPoolContractsByChainId(chainId_);
        break;
      case 137:
        networkName = 'Polygon Mainnet';
        chainId = 137;
        networkURL = 'https://rpc-mainnet.matic.network';
        poolContracts = getPoolContractsByChainId(chainId_);
        fixedPoolContracts = getFixedPoolContractsByChainId(chainId_);
        break;
      case 80001:
        networkName = 'Mumbai Matic';
        chainId = 80001;
        networkURL = 'https://restless-snowy-violet.matic-testnet.quiknode.pro/775d8c4bcdcc416b63a6f6c037f74c80ac935877/';
        poolContracts = getPoolContractsByChainId(chainId_);
        fixedPoolContracts = getFixedPoolContractsByChainId(chainId_);
        break;
      case 3:
        networkName = 'ropsten';
        chainId = 3;
        networkURL = `https://ropsten.infura.io/v3/${projectID}`;
        poolContracts = getPoolContractsByChainId(chainId_);
        fixedPoolContracts = getFixedPoolContractsByChainId(chainId_);
        deploymentBlockNumber = 9842940;
        break;
      case 5:
        networkName = 'goerli';
        chainId = 5;
        networkURL = `https://goerli.infura.io/v3/${projectID}`;
        poolContracts = getPoolContractsByChainId(chainId_);
        fixedPoolContracts = getFixedPoolContractsByChainId(chainId_);
        deploymentBlockNumber = 9842940;
        break;
      case 1:
        networkName = 'mainnet';
        chainId = 1;
        networkURL = `https://mainnet.infura.io/v3/${projectID}`;
        poolContracts = getPoolContractsByChainId(chainId_);
        fixedPoolContracts = getFixedPoolContractsByChainId(chainId_);
        etherscan = 'https://etherscan.io/tx/';
        deploymentBlockNumber = 10847676;
        break;
      case 43114:
        networkName = 'avalanche';
        chainId = 43114;
        networkURL = 'https://api.avax.network/ext/bc/C/rpc';
        poolContracts = {};
        fixedPoolContracts = getFixedPoolContractsByChainId(chainId_);
        etherscan = 'https://cchain.explorer.avax.network/';
        deploymentBlockNumber = 10847676;
        break;
      case 43113:
        networkName = 'avalanche testnet';
        chainId = 43113;
        networkURL = 'https://api.avax-test.network/ext/bc/C/rpc';
        poolContracts = {};
        fixedPoolContracts = getFixedPoolContractsByChainId(chainId_);
        etherscan = 'https://testnet.explorer.avax.network/';
        deploymentBlockNumber = 10847676;
        break;

      default:
        etherscan = 'https://etherscan.io/tx/';
    }
  }
};

export const getNetworkName = () => {
  return networkName;
};
export const getPoolContractById = (id) => {
  return poolContracts[id];
};
export const getPoolContracts = () => {
  return poolContracts;
};
export const getDeploymentBlock = () => {
  return deploymentBlockNumber;
};
export const getChainId = () => {
  return chainId;
};

// fixed farming pools
export const getFixedPoolContractById = (id) => {
  return fixedPoolContracts[id];
};
export const getFixedPoolContracts = () => {
  return fixedPoolContracts;
};

export const SupportedNetworks = supportedNetworks;
export const EtherScanLink = etherscan;
export const MinSubsequntReqMinutes = 0;
export const DefaultAmount = 100;
export const cdnHostUrl = process.env.REACT_APP_CDN_HOST_URL;

export const chainIDsEnum = {
  mainnet: 1,
  ropsten: 3,
  rinkeby: 4,
  goerli: 5,
  kovan: 42,
  bscTestnet: 97,
  bscMainnet: 56,
  avaxTestnet: 43113,
  avaxMainnet: 43114,
  matic: 137,
  mumbai: 80001
};

export const NetworksEnums = {
  1: {
    id: 1,
    jsonrpc: '2.0',
    method: 'wallet_switchEthereumChain',
    params: [
      {
        chainId: '0x1'
      }
    ]
  },
  56: {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com/']
  },
  97: {
    chainId: '0x61',
    chainName: 'Binance Smart Chain Test',
    nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com/']
  },
  137: {
    chainId: '0x89',
    chainName: 'Polygon',
    nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/']
  },
  80001: {
    chainId: '0x13881',
    chainName: 'mumbai',
    nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
    rpcUrls: ['https://restless-snowy-violet.matic-testnet.quiknode.pro/775d8c4bcdcc416b63a6f6c037f74c80ac935877/'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com/']
  },
  43114: {
    chainId: '0xa86a',
    chainName: 'Avalanche Mainnet C-Chain',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://cchain.explorer.avax.network/']
  },
  43113: {
    chainId: '0xa869',
    chainName: 'Avalanche Testnet C-Chain',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://explorer.avax-test.network/']
  }
};

export const secondsPerBlock = {
  43114: 1,
  1: 13.08,
  3: 1,
  4: 1,
  137: 1,
  80001: 1
};
